import Image from "next/image";
import clsx from "clsx";
import { inject, observer } from "mobx-react";
import { Store } from "stores/store";
import { getStrapiAssetUrl, getImageAttributes } from "utils/imageHelpers";
import { VideoEnums } from "enums/Video";
import { useEffect, useRef } from "react";

interface VideoProps {
    store?: Store;
}

const Video: React.FunctionComponent<VideoProps> = ({ store }) => {
    if (!store) {
        throw Error("Store was not setup correctly");
    }

    const { currentVideo, updateCurrentVideo, videoDataLocalized, updateEndScreenOverlay } = store;

    const beginningLoop = useRef<HTMLVideoElement | null>(null);
    const main = useRef<HTMLVideoElement | null>(null);
    const endLoop = useRef<HTMLVideoElement | null>(null);

    const frameImage = videoDataLocalized?.frame.data?.attributes?.url
        ? getImageAttributes(videoDataLocalized?.frame as any)
        : null;

    const videoWrapperClasses = "absolute top-[32px] left-5 right-5 sm:top-[46px] sm:left-9 sm:right-9";
    const videoClasses = "w-full rounded-[38px] sm:rounded-[52px]";

    useEffect(() => {
        if (currentVideo === VideoEnums.MAIN) {
            main.current?.play();
            setTimeout(() => beginningLoop.current?.pause(), 1000);
        }

        if (currentVideo === VideoEnums.END) {
            endLoop.current?.play();

            setTimeout(() => {
                updateEndScreenOverlay(true);
            }, 2000);
        }
    }, [currentVideo]);

    function onEndedMainVideo() {
        updateCurrentVideo(VideoEnums.END);
    }

    return (
        <div className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 w-[320px] aspect-square sm:w-[350px] rotate-[-5deg]">
            <div className="relative w-full h-full">
                {videoDataLocalized?.beginningLoop.data?.attributes?.url && (
                    <div className={videoWrapperClasses}>
                        <video
                            autoPlay
                            loop
                            muted
                            playsInline
                            className={videoClasses}
                            ref={beginningLoop}
                            poster={
                                videoDataLocalized?.beginningLoopPoster.data?.attributes?.url
                                    ? getStrapiAssetUrl(videoDataLocalized?.beginningLoopPoster.data?.attributes?.url)
                                    : ""
                            }
                        >
                            <source
                                src={getStrapiAssetUrl(videoDataLocalized?.beginningLoop.data?.attributes?.url)}
                                type={videoDataLocalized?.beginningLoop.data?.attributes?.mime}
                            />
                        </video>
                    </div>
                )}

                {videoDataLocalized?.main.data?.attributes?.url && (
                    <div
                        className={clsx(
                            videoWrapperClasses,
                            "transition-opacity duration-1000",
                            currentVideo === VideoEnums.MAIN ? "opacity-100" : "opacity-0"
                        )}
                    >
                        <video playsInline className={videoClasses} ref={main} onEnded={onEndedMainVideo}>
                            <source
                                src={getStrapiAssetUrl(videoDataLocalized?.main.data?.attributes?.url)}
                                type={videoDataLocalized?.beginningLoop.data?.attributes?.mime}
                            />
                        </video>
                    </div>
                )}

                {videoDataLocalized?.endLoop.data?.attributes?.url && (
                    <div
                        className={clsx(
                            videoWrapperClasses,
                            currentVideo === VideoEnums.END ? "opacity-100" : "opacity-0"
                        )}
                    >
                        <video muted loop playsInline className={videoClasses} ref={endLoop}>
                            <source
                                src={getStrapiAssetUrl(videoDataLocalized?.endLoop.data?.attributes?.url)}
                                type={videoDataLocalized?.beginningLoop.data?.attributes?.mime}
                            />
                        </video>
                    </div>
                )}
            </div>
            {frameImage && (
                <>
                    <Image
                        src={frameImage.src}
                        alt={frameImage.alt || ""}
                        width={frameImage.width}
                        height={frameImage.height}
                        className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 pointer-events-none"
                    />
                    <Image
                        src="/images/corners.gif"
                        width={710}
                        height={710}
                        alt=""
                        className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 pointer-events-none scale-50"
                    />
                </>
            )}
        </div>
    );
};

export default inject("store")(observer(Video));
