import clsx from "clsx";
import { PropsWithChildren } from "react";

interface CloseButtonProps {}

const CloseButton: React.FunctionComponent<
    CloseButtonProps & PropsWithChildren & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ children, ...props }) => {
    return (
        <button
            {...props}
            className={clsx(
                "bg-black25 hover:bg-black100 transition-[background] duration-300 w-[36px] h-[36px] p-[2px] rounded-full text-center block",
                props.className
            )}
        >
            <span className="w-[32px] h-[32px] border p-3 flex items-center justify-center rounded-full">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    className="shrink-0"
                >
                    <rect
                        x="6.46021"
                        y="16.8086"
                        width="15.2"
                        height="1.6"
                        transform="rotate(-45 6.46021 16.8086)"
                        fill="white"
                    />
                    <rect
                        x="7.5918"
                        y="6.06055"
                        width="15.2"
                        height="1.6"
                        transform="rotate(45 7.5918 6.06055)"
                        fill="white"
                    />
                </svg>
            </span>
        </button>
    );
};

export default CloseButton;
