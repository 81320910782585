import { useEffect } from "react";

export function useOnClickOutside(ref: React.RefObject<any>, handler: Function) {
  useEffect(() => {
    const listener = (event: TouchEvent | MouseEvent) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      handler(event);
    };

    document.addEventListener("mousedown", listener, {passive: true});
    document.addEventListener("touchstart", listener, {passive: true});

    return () => {
      document.removeEventListener("mousedown", listener );
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}