/** @type {import("tailwindcss").Config} */
const { fontFamily } = require("tailwindcss/defaultTheme");

const contentWidth = "34rem";

module.exports = {
    content: [
        "./components/**/*.{js,ts,jsx,tsx}", // Or if using `src` directory:
        "./src/**/*.{js,ts,jsx,tsx}",
    ],
    future: { hoverOnlyWhenSupported: true },
    theme: {
        screens: {
            xs: "480px",
            sm: "640px",
            md: "768px",
            lg: "1024px",
            xl: "1280px",
            "2xl": "1536px",
            "3xl": "2500px",
            custom: contentWidth,
        },
        extend: {
            transitionProperty: {
                height: "height",
            },
            borderRadius: {
                sm: "4px",
                DEFAULT: "8px",
                lg: "12px",
            },
            colors: {
                gold: "#A59051",
                brown: "#463926",
                black65: "rgba(0, 0, 0, 0.65)",
                black25: "rgba(0, 0, 0, 0.25)",
                black100: "rgba(0, 0, 0, 1)",
            },
            maxWidth: {
                content: contentWidth,
            },
            fontFamily: {
                serif: ['"Source Serif Pro"', ...fontFamily.serif],
                sans: ['"Source Sans Pro"', ...fontFamily.sans],
                tesla: ['"Tesla"'],
            },
            transitionTimingFunction: {
                main: "cubic-bezier(0.5, 0, 0.25, 1)",
            },
            fontSize: {
                // size: [fontSize, lineHeight]
                xxs: ["0.5rem", "1.25rem"], // 8px/20px
                xs: ["0.75rem", "1.5rem"], // 12px/24px
                sm: ["0.875rem", "1.25rem"], // 14px/20px
                base: ["1rem", "1.25rem"], // 16px/20px
                lg: ["1.125rem", "1.25rem"], // 18px/20px
            },
            keyframes: {
                toggle: {
                    "0%": { opacity: "0" },
                    "50%": { opacity: "1" },
                    "100%": { opacity: "0" },
                },
            },
            animation: {
                toggle: "toggle 0.6s infinite",
            },
            boxShadow: {
                toast: "0px 2px 4px 0px rgba(0, 0, 0, 0.10), 0px 4px 8px 0px rgba(0, 0, 0, 0.10), 0px 8px 16px 0px rgba(0, 0, 0, 0.10);",
            },
        },
    },
    plugins: [],
};
