/* eslint-disable @next/next/no-img-element */
import clsx from "clsx";
import { inject, observer } from "mobx-react";
import { PropsWithChildren, useEffect, useRef, useState } from "react";
import { Store } from "stores/store";
import { getImageAttributes } from "utils/imageHelpers";
import { VideoEnums } from "enums/Video";

import Image from "next/image";
import SequenceBar from "./SequenceBar";

interface BackgroundProps {
    store?: Store;
}

const Background: React.FunctionComponent<BackgroundProps & PropsWithChildren> = ({ children, store }) => {
    if (!store) {
        throw Error("Store was not setup correctly");
    }

    const [showHand, setShowHand] = useState(false);
    const handTimer = useRef<NodeJS.Timeout | null>(null);

    const {
        isConnected,
        currentVideo,
        updateIsConnected,
        updateCurrentVideo,
        updateShowSequenceBar,
        updateShowSequenceOverlay,
        basicUiDataLocalized,
    } = store;

    const backgroundImage = basicUiDataLocalized?.background.data?.attributes?.url
        ? getImageAttributes(basicUiDataLocalized?.background as any)
        : null;

    const handImage = basicUiDataLocalized?.handIcon.data?.attributes?.url
        ? getImageAttributes(basicUiDataLocalized?.handIcon as any)
        : null;

    const connectionNoteImage = basicUiDataLocalized?.connectionNote.data?.attributes?.url
        ? getImageAttributes(basicUiDataLocalized?.connectionNote as any)
        : null;

    useEffect(() => {
        if (!isConnected) {
            handTimer.current = setTimeout(() => {
                setShowHand(true);
            }, basicUiDataLocalized?.handIconWillBeDisplayedAfter || 5000);
        } else {
            clearTimeout(handTimer.current as NodeJS.Timeout);
            setShowHand(false);
        }
    }, [isConnected]);

    function startAnimation() {
        if (!isConnected) {
            updateIsConnected(true);
            updateCurrentVideo(VideoEnums.MAIN);

            setTimeout(() => {
                updateShowSequenceBar(true);
            }, 1000);

            setTimeout(() => {
                updateShowSequenceOverlay(true);
            }, 3000);
        }
    }

    return (
        <div
            className={clsx(
                "relative w-full h-full transition-transform duration-[1500ms] ease-main",
                isConnected && currentVideo !== VideoEnums.END
                    ? "delay-[1200ms] rotate-[5deg] scale-[1] xs:scale-[1.3] translate-y-[80px]"
                    : "scale-[0.8] xs:scale-[1]"
            )}
        >
            {!!backgroundImage && (
                <Image
                    src={backgroundImage.src}
                    alt={backgroundImage.alt || ""}
                    width={backgroundImage.width ? backgroundImage.width / 2 : 0}
                    height={backgroundImage.height ? backgroundImage.height / 2 : 0}
                    className={clsx("absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 3xl:w-full")}
                />
            )}

            <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 ">
                {connectionNoteImage && (
                    <Image
                        src={connectionNoteImage.src}
                        alt={connectionNoteImage.alt || ""}
                        width={connectionNoteImage.width}
                        height={connectionNoteImage.height}
                        className={clsx(
                            "absolute top-[68%] left-[60%] sm:left-[70%] rotate-[10deg] transition-[transform,opacity] duration-[800ms] ease-main",
                            (isConnected || currentVideo !== VideoEnums.BEGINNING) &&
                                "rotate-[35deg] translate-x-[50%] translate-y-[70%] opacity-0"
                        )}
                    />
                )}
                <div className="relative translate-y-[-3%] translate-x-[3%]">
                    <Image src="/images/cables.png" width={841} height={801} alt="" />
                    <div className="absolute top-[19.5%] right-[33.9%] scale-[0.96]">
                        <SequenceBar />
                    </div>

                    <div className={!isConnected ? "cursor-pointer" : ""} onClick={startAnimation}>
                        <Image
                            src="/images/plug-out.png"
                            width={192}
                            height={97}
                            className={clsx(
                                "absolute bottom-[18.8%] left-[43%] transition-transform duration-[800ms] ease-main -rotate-[1deg]",
                                isConnected && "translate-x-[-14px] translate-y-[5px]"
                            )}
                            alt=""
                        />

                        <Image
                            src="/images/plug-in.png"
                            width={167}
                            height={99}
                            className={clsx(
                                "absolute bottom-[16%] left-[26%] transition-transform duration-[800ms] ease-main rotate-[1deg]",
                                isConnected && "translate-x-[26px] translate-y-[0px]"
                            )}
                            alt=""
                        />

                        <Image
                            src="/images/sparks.gif"
                            width={656}
                            height={480}
                            className={clsx(
                                "absolute bottom-[-5%] left-[5%] transition-transform duration-[800ms] ease-main pointer-events-none",
                                isConnected && "translate-x-[25px]",
                                currentVideo === VideoEnums.MAIN && "opacity-0"
                            )}
                            alt=""
                            quality={100}
                        />

                        {handImage && (
                            <Image
                                src={handImage.src}
                                width={handImage.width || 0}
                                height={handImage.height || 0}
                                alt={handImage.alt || ""}
                                className={clsx(
                                    "absolute bottom-[13%] left-[44%] opacity-1 transition-opacity ease-main animate-bounce",
                                    showHand && !isConnected && "opacity-1 duration-[400ms]",
                                    ((!showHand && !isConnected) || isConnected) && "opacity-0 duration-[100ms]"
                                )}
                            />
                        )}
                    </div>
                </div>
            </div>

            {children}
        </div>
    );
};

export default inject("store")(observer(Background));
