import { inject, observer } from "mobx-react";
import clsx from "clsx";
import { Store } from "stores/store";
import Image from "next/image";

import { getStrapiAssetUrl } from "utils/imageHelpers";
import { getDeviceOs } from "utils/deviceHelper";
import Markdown from "components/Markdown";
import Button from "components/Button";
import QrCode from "components/QrCode";
import { useEffect, useState } from "react";

interface EndScreenOverlayProps {
    store?: Store;
}

const EndScreenOverlay: React.FunctionComponent<EndScreenOverlayProps> = ({ store }) => {
    if (!store) {
        throw Error("Store was not setup correctly");
    }

    const [deviceOs, setDeviceOs] = useState<string | null>(null);
    const [qrCodeUrl, setQrCodeUrl] = useState<string | null>(null);

    const { showEndScreenOverlay, endScreenOverlayDataLocalized } = store;

    useEffect(() => {
        setQrCodeUrl(location.protocol + "//" + location.host + location.pathname);
        setDeviceOs(getDeviceOs());
    }, []);

    return (
        <div
            className={clsx(
                "absolute top-0 right-0 bottom-0 left-0 flex items-center justify-center transition-[opacity,z-index] duration-500",
                showEndScreenOverlay ? "opacity-100 z-[100]" : "opacity-0 z-[-1]"
            )}
        >
            <div className="absolute w-full h-full bg-black bg-opacity-50" />
            <div
                className={clsx(
                    "relative z-[101] max-w-[calc(100%-32px)] lg:max-w-[300px] p-4 mx-2 rounded-md overflow-hidden transition-transform duration-[1500ms] ease-main",
                    showEndScreenOverlay
                        ? "rotate-[2.3deg] translate-y-0 duration-[1500ms]"
                        : "rotate-[-10deg] translate-y-[-100%] duration-[0ms]"
                )}
            >
                <Image src="/images/texture.jpg" alt="" fill />
                <div className="relative text-brown flex flex-col gap-4">
                    {endScreenOverlayDataLocalized?.image.data?.attributes?.url && (
                        <Image
                            src={getStrapiAssetUrl(endScreenOverlayDataLocalized?.image.data?.attributes?.url)}
                            width={endScreenOverlayDataLocalized?.image.data?.attributes?.width || 0}
                            height={endScreenOverlayDataLocalized?.image.data?.attributes?.height || 0}
                            alt={endScreenOverlayDataLocalized?.image.data?.attributes?.alternativeText || ""}
                            className="mx-auto"
                        />
                    )}

                    {!!endScreenOverlayDataLocalized?.text && (
                        <Markdown className="font-serif text-sm" source={endScreenOverlayDataLocalized?.text} />
                    )}

                    <div className="font-tesla text-right">
                        <div className="text-sm">{endScreenOverlayDataLocalized?.greetings}</div>
                        <div className="text-lg">{endScreenOverlayDataLocalized?.greetingsName}</div>
                    </div>

                    {deviceOs && (
                        <Button
                            href={
                                deviceOs === "ios"
                                    ? endScreenOverlayDataLocalized?.appStoreUrl
                                    : endScreenOverlayDataLocalized?.playStoreUrl
                            }
                            target="_blank"
                            className="lg:hidden"
                        >
                            {endScreenOverlayDataLocalized?.buttonLabel}
                        </Button>
                    )}

                    {qrCodeUrl && endScreenOverlayDataLocalized?.qrCodeText && (
                        <div className="hidden lg:block">
                            <QrCode url={`${qrCodeUrl}?referrer=qr`} size={128} className="mx-auto" />
                            <div className="text-xxs opacity-[0.65] text-center mt-1 font-serif">
                                {endScreenOverlayDataLocalized.qrCodeText}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default inject("store")(observer(EndScreenOverlay));
