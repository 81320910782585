import Head from "next/head";
import { MetaEntity } from "types/strapi";
import { getFileUrl } from "utils/imageHelpers";
import tailwindConfig from "tailwind.config";

interface MetaDataProps {
    metaData: MetaEntity;
}

const MetaData: React.FunctionComponent<MetaDataProps> = ({ metaData }) => {
    return (
        <Head>
            <title>{metaData.attributes?.pageTitle}</title>
            <meta name="description" content={metaData.attributes?.metaDescription} />
            <meta
                name="theme-color"
                content={metaData.attributes?.themeColor || tailwindConfig.theme.extend.colors["gold"]}
            />

            {metaData.attributes?.ogTitle && <meta property="og:title" content={metaData.attributes?.ogTitle} />}
            {metaData.attributes?.ogDescription && (
                <meta property="og:description" content={metaData.attributes?.ogDescription} />
            )}
            {metaData.attributes?.ogImage?.data && (
                <meta property="og:image" content={getFileUrl(metaData.attributes?.ogImage)} />
            )}
        </Head>
    );
};

export default MetaData;
