import QRCode from "react-qr-code";
import tailwindConfig from "tailwind.config";

interface QrCodeProps {
    url: string;
    size?: number;
    className?: string;
}

const QrCode: React.FunctionComponent<QrCodeProps> = ({ url, size, className }) => {
    return (
        <QRCode
            value={url}
            bgColor="transparent"
            fgColor={tailwindConfig.theme.extend.colors.brown}
            size={size}
            className={className}
        />
    );
};

export default QrCode;
