import { ComponentGeneralImage, UploadFileEntity, UploadFileEntityResponse, UploadFile } from "types/strapi";
import { prependPageUrl, prependStrapiUrl } from "utils/strapiAssetUrls";
import { ENV_APP_ENV } from "utils/env.dts";

interface ImageHelperData {
    data?: { attributes: UploadFile };
    isFilled?: boolean;
}

export const getImageAttributes = (
    image: ImageHelperData,
    isFilled: boolean = false
): {
    src: string;
    width?: number;
    height?: number;
    alt: string;
} => {
    if (!image || !image.data || !image.data?.attributes) {
        throw new Error("Missing image data");
    }

    const { width, height, alternativeText, url } = image.data.attributes;

    const obj: any = {
        src: getStrapiAssetUrl(url),
        alt: alternativeText || "",
    };

    if (!isFilled) {
        obj.width = width || 0;
        obj.height = height || 0;
    }

    return obj;
};

export const getImageUrlFromGeneralImage = (imageData: ComponentGeneralImage): string => {
    if (!imageData.image.data || !imageData.image.data.attributes) {
        throw new Error("Missing image data");
    }

    const assetUrl = getStrapiAssetUrl(imageData.image.data.attributes.url);

    if (process.env.NEXT_PUBLIC_IS_LIVE !== "true") {
        return assetUrl;
    }

    return assetUrl;
};

export const getFileUrl = (fileData: UploadFileEntityResponse): string => {
    if (!fileData.data || !fileData.data.attributes) {
        throw new Error("Missing image data");
    }

    return getStrapiAssetUrl(fileData.data.attributes.url);
};

export const getFileUrlFromArrayElement = (fileData: UploadFileEntity): string => {
    if (!fileData.attributes) {
        throw new Error("Missing image data");
    }

    return getStrapiAssetUrl(fileData.attributes.url);
};

export const getAbsoluteImageUrlFromGeneralImage = (imageData: ComponentGeneralImage): string => {
    if (!imageData.image.data || !imageData.image.data.attributes) {
        throw new Error("Missing image data");
    }

    const imageUrl = imageData.image.data.attributes.url;

    // Due to our customised image export process, we need to ensure that in the
    // production build only the client side code return an absolute path pointing to
    // the url the page is served from. In all other scenarios, the strapi url should
    // be prepended.
    if (ENV_APP_ENV === "production" && typeof window !== "undefined") {
        return prependPageUrl(imageUrl);
    }
    return prependStrapiUrl(imageUrl);
};

export const getAltTextFromGeneralImage = (imageData: ComponentGeneralImage): string => {
    if (!imageData.image.data || !imageData.image.data.attributes) {
        throw new Error("Missing image data");
    }
    return imageData.altText || imageData.image.data.attributes.name;
};

export const getUrlFromUploadedFile = (fileData: UploadFileEntityResponse): string => {
    if (!fileData.data || !fileData.data.attributes) {
        throw new Error("Missing file data");
    }

    return getStrapiAssetUrl(fileData.data.attributes.url);
};

export const getMimeFromUploadedFile = (fileData: UploadFileEntityResponse): string => {
    if (!fileData.data || !fileData.data.attributes) {
        throw new Error("Missing file data");
    }

    return fileData.data.attributes.mime;
};

// Due to our customised image export process, we need to ensure that in the
// production build only the client side code return relative paths. In all other
// scenarios, the strapi url should be prepended.
export const getStrapiAssetUrl = (path: string) => {
    if (ENV_APP_ENV === "production" && typeof window !== "undefined") {
        // console.log('Path: ' + path)
        return path;
    }
    return prependStrapiUrl(path);
};
