import clsx from "clsx";
import { PropsWithChildren } from "react";

interface ButtonProps {}

const Button: React.FunctionComponent<
    ButtonProps & PropsWithChildren & React.AnchorHTMLAttributes<HTMLAnchorElement>
> = ({ children, ...props }) => {
    return (
        <a
            {...props}
            className={clsx("bg-black text-white text-base p-[2px] rounded-sm text-center block", props.className)}
        >
            <span className="border p-3 block rounded-sm">{children}</span>
        </a>
    );
};

export default Button;
