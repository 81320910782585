import clsx from "clsx";
import { PropsWithChildren } from "react";
import ReactMarkdown from "react-markdown";

interface MarkdownProps {
    source: string;
    className?: string;
}

const Markdown: React.FunctionComponent<MarkdownProps> = ({ source, className }) => {
    let md = source || "";

    md = md.replace(/```[\s\S]*?```/g, (m) => m.replace(/\n/g, "\n "));
    md = md.replace(/(\n\n)(?![*-])\n/g, (match, p1) => p1 + "&nbsp;\n ");
    md = md.replace(/(\n)/gm, "  \n");

    return (
        <ReactMarkdown
            className={className}
            components={{
                p: ({ children }) => <p style={{ marginBottom: "1em" }}>{children}</p>,
            }}
        >
            {md}
        </ReactMarkdown>
    );
};

export default Markdown;
