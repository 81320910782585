import Image from "next/image";
import { inject, observer } from "mobx-react";
import clsx from "clsx";
import { Store } from "stores/store";
import { useEffect, useState, useRef } from "react";

interface SequenceOverlayProps {
    store?: Store;
}

const SequenceOverlay: React.FunctionComponent<SequenceOverlayProps> = ({ store }) => {
    if (!store) {
        throw Error("Store was not setup correctly");
    }

    const { showSequenceOverlay, updateShowSequenceOverlay } = store;
    const interval = useRef<ReturnType<typeof setInterval> | null>(null);

    const [currentSequence, setCurrentSequence] = useState(-1);
    const [device, setDevice] = useState<"desktop" | "mobile" | null>(null);

    const files = {
        mobile: 61,
        desktop: 43,
    };

    const fps = 15;

    function clearSequence() {
        clearInterval(interval.current as ReturnType<typeof setInterval>);
        updateShowSequenceOverlay(false);
    }

    useEffect(() => {
        setDevice(window.innerWidth >= 1024 ? "desktop" : "mobile");

        const handleResize = () => setDevice(window.innerWidth >= 1024 ? "desktop" : "mobile");
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        if (device === null) return;

        if (showSequenceOverlay) {
            let count = 0;
            interval.current = setInterval(() => {
                setCurrentSequence((prev) => prev + 1);
                count++;

                if (count >= files[device]) {
                    clearSequence();
                }
            }, 1000 / fps);
        } else {
            clearSequence();
        }

        return () => {
            setCurrentSequence(-1);

            if (interval.current) {
                clearInterval(interval.current);
                interval.current = null;
            }
        };
    }, [showSequenceOverlay]);

    if (device === null) return <></>;

    return (
        <div className={clsx("absolute top-0 left-0 w-full h-full", showSequenceOverlay ? "block" : "hidden")}>
            {[...Array(files[device])].map((e, id) => {
                return (
                    <Image
                        key={id}
                        src={`/images/sequence-overlay-${device}/Comp 2_000${id < 10 ? `0${id}` : id}.webp`}
                        className={clsx(
                            "object-cover transition-colors duration-[10000ms] translate-y-[15%] xs:translate-y-[12%] scale-[1.3]",
                            id === currentSequence - 1 ? "opacity-1" : "opacity-0"
                        )}
                        alt=""
                        fill
                    />
                );
            })}
        </div>
    );
};

export default inject("store")(observer(SequenceOverlay));
