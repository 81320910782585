import Image from "next/image";
import { inject, observer } from "mobx-react";
import clsx from "clsx";
import { Store } from "stores/store";
import { getImageAttributes } from "utils/imageHelpers";

interface ToastProps {
    store?: Store;
    visible?: boolean;
    text: string;
}

const Toast: React.FunctionComponent<ToastProps> = ({ visible, store, text }) => {
    if (!store) {
        throw Error("Store was not setup correctly");
    }

    const { toastDataLocalized } = store;

    const Arrow = ({ ...props }: React.SVGAttributes<HTMLOrSVGElement>) => (
        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="6" viewBox="0 0 13 6" fill="none" {...props}>
            <path
                d="M1.24002 3.48251C0.818104 3.29891 0.818104 2.70051 1.24002 2.51691L5.5443 0.643935C5.89149 0.492857 6.29576 0.569552 6.56349 0.837288L7.79513 2.06893C8.30919 2.58299 8.30919 3.41643 7.79514 3.93048L6.56349 5.16213C6.29576 5.42987 5.89149 5.50656 5.5443 5.35548L1.24002 3.48251Z"
                fill="white"
            />
            <circle cx="10.792" cy="2.937" r="1.57958" fill="white" />
        </svg>
    );

    return (
        <div
            className={clsx(
                "text-base p-[2px] fixed z-20 top-[24px] translate-y-[calc(-100%-36px)] sm:top-[64px] sm:translate-y-[calc(-100%-66px)] left-1/2 -translate-x-1/2 w-[calc(100%-44px)] sm:w-[410px] bg-black65 text-white font-serif rounded-lg ease-main transition-[transform,box-shadow] duration-[800ms]",
                visible && "!translate-y-0 shadow-toast"
            )}
        >
            <div className="p-3 relative w-full h-full border rounded-lg flex gap-3 items-center">
                <div className="z-21 absolute left-1/2 top-0 -translate-x-1/2 translate-y-[calc(-50%-6px)] flex items-center gap-3 px-3 py-[2px] font-sans font-black uppercase whitespace-nowrap bg-gold rounded-[16px]">
                    <Arrow />
                    {toastDataLocalized?.topLabel}
                    <Arrow className="rotate-180" />
                </div>
                <div className="relative w-[61px] h-[61px] shrink-0">
                    {toastDataLocalized?.avatar1 && (
                        <Image {...getImageAttributes(toastDataLocalized?.avatar1 as any, true)} alt="" fill />
                    )}
                    {toastDataLocalized?.avatar2 && (
                        <Image
                            {...getImageAttributes(toastDataLocalized?.avatar2 as any, true)}
                            alt=""
                            fill
                            className="animate-toggle"
                        />
                    )}
                </div>
                {text}
            </div>
        </div>
    );
};

export default inject("store")(observer(Toast));
