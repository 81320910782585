import { inject, observer } from "mobx-react";
import { Store } from "stores/store";
import Toast from "components/Toast";
import { useEffect, useState } from "react";

interface ToastsProps {
    store?: Store;
}

const Toasts: React.FunctionComponent<ToastsProps> = ({ store }) => {
    if (!store) {
        throw Error("Store was not setup correctly");
    }

    const { toastDataLocalized } = store;
    const [visibleIndex, setVisibleIndex] = useState<number | null>(null);

    function showToast(duration: number, index: number) {
        setVisibleIndex(index);

        setTimeout(() => {
            setVisibleIndex(null);
        }, duration);
    }

    useEffect(() => {
        if (toastDataLocalized?.items && toastDataLocalized?.items.length > 0) {
            toastDataLocalized?.items.forEach((toast, index) => {
                if (toast) {
                    setTimeout(() => {
                        showToast(toast.durationInMilliseconds, index);
                    }, toast.slideInAfterMilliseconds);
                }
            });
        }
    }, [toastDataLocalized]);

    return (
        <div>
            {toastDataLocalized?.items.map((toast, index) => (
                <Toast key={index} visible={visibleIndex === index} text={toast?.text || ""} />
            ))}
        </div>
    );
};

export default inject("store")(observer(Toasts));
