export const getDeviceOs = (): string => {
    if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
        return "android";
    }
    if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1) {
        return "ios";
    }

    return "unknown";
};
