import Image from "next/image";
import { inject, observer } from "mobx-react";
import clsx from "clsx";
import { Store } from "stores/store";
import { useEffect, useState, useRef } from "react";

interface SequenceBarProps {
    store?: Store;
}

const SequenceBar: React.FunctionComponent<SequenceBarProps> = ({ store }) => {
    if (!store) {
        throw Error("Store was not setup correctly");
    }

    const { showSequenceBar, updateShowSequenceBar } = store;
    const interval = useRef<ReturnType<typeof setInterval> | null>(null);

    const [currentSequence, setCurrentSequence] = useState(0);

    const files = 16;
    const fps = 15;

    function clearSequence() {
        clearInterval(interval.current as ReturnType<typeof setInterval>);
        // updateShowSequenceBar(false);
    }

    useEffect(() => {
        if (showSequenceBar) {
            let count = 0;
            interval.current = setInterval(() => {
                setCurrentSequence((prev) => prev + 1);
                count++;

                if (count >= files - 1) {
                    clearSequence();
                }
            }, 1000 / fps);
        } else {
            clearSequence();
        }

        return () => {
            if (interval.current) {
                clearInterval(interval.current);
                interval.current = null;
            }
        };
    }, [showSequenceBar]);

    return (
        <div className={clsx("relative w-[50px] h-[50px]")} data-current={currentSequence}>
            {[...Array(files)].map((e, id) => {
                return (
                    <Image
                        key={id}
                        src={`/images/sequence-bar/BarFill_000${id < 10 ? `0${id}` : id}.webp`}
                        className={clsx(
                            "absolute top-0 left-0 transition-colors duration-[10000ms]",
                            id === currentSequence ? "opacity-1" : "opacity-0"
                        )}
                        alt=""
                        fill
                    />
                );
            })}
        </div>
    );
};

export default inject("store")(observer(SequenceBar));
