import { inject, observer } from "mobx-react";
import clsx from "clsx";
import Image from "next/image";
import { Store } from "stores/store";
import { getImageAttributes } from "utils/imageHelpers";
import dynamic from "next/dynamic";
import { useState, TouchEvent, useRef } from "react";

import { useOnClickOutside } from "utils/hooks/useOnClickOutside";

import CloseButton from "components/CloseButton";

const Carousel = dynamic(
    // @ts-expect-error
    () => import("react-spring-3d-carousel"),
    {
        ssr: false,
    }
);

interface NotesOverlayProps {
    store?: Store;
}

const NotesOverlay: React.FunctionComponent<NotesOverlayProps> = ({ store }) => {
    if (!store) {
        throw Error("Store was not setup correctly");
    }

    const [state, setState] = useState<any>({
        goToSlide: 0,
        offsetRadius: 1,
        showNavigation: false,
        config: { tension: 120, friction: 14 },
    });

    const sliderRef = useRef<HTMLDivElement>(null);

    const { notesOverlayDataLocalized, showNotesOverlay, updateShowNotesOverlay } = store;

    const slides: any = [];

    useOnClickOutside(sliderRef, () => {
        updateShowNotesOverlay(false);
    });

    notesOverlayDataLocalized?.items.map((slide, index) => {
        const image = slide?.image.data?.attributes?.url ? getImageAttributes(slide?.image as any) : null;

        if (image) {
            slides.push({
                key: index,
                content: (
                    <Image
                        src={image.src}
                        width={image.width || 0}
                        height={image.height || 0}
                        alt={image.alt || ""}
                        className={clsx(
                            "max-w-[80vw] lg:max-w-[600px]",
                            showNotesOverlay
                                ? "rotate-[0deg] translate-y-0 duration-[500ms]"
                                : "rotate-[-15deg] translate-y-[100%] duration-[0ms] delay-500"
                        )}
                        onClick={() => onChangeInput(index)}
                    />
                ),
            });
        }
    });

    const onChangeInput = (idx: number) => {
        setState({
            goToSlide: idx,
        });
    };

    let xDown: number | null = null;
    let yDown: number | null = null;

    const getTouches = (evt: any) => {
        return evt.touches || evt.originalEvent.touches;
    };

    const handleTouchStart = (evt: TouchEvent<HTMLDivElement>) => {
        const firstTouch = getTouches(evt)[0];
        xDown = firstTouch.clientX;
        yDown = firstTouch.clientY;
    };

    const handleTouchMove = (evt: TouchEvent<HTMLDivElement>) => {
        if (!xDown || !yDown) {
            return;
        }

        let xUp = evt.touches[0].clientX;
        let yUp = evt.touches[0].clientY;

        let xDiff = xDown - xUp;
        let yDiff = yDown - yUp;

        if (Math.abs(xDiff) > Math.abs(yDiff)) {
            /*most significant*/
            if (xDiff > 0) {
                /* left swipe */
                setState({ goToSlide: state.goToSlide + 1 });
            } else {
                /* right swipe */
                setState({ goToSlide: state.goToSlide - 1 });
            }
        } else {
            if (yDiff > 0) {
                /* up swipe */
            } else {
                /* down swipe */
            }
        }
        /* reset values */
        xDown = null;
        yDown = null;
    };

    const getDefaultTranslateX = (offsetFromCenter: number, offsetRadius: number, index: number) => {
        const totalPresentables = 2 * offsetRadius + 1;
        const translateXoffset = 50 * (Math.abs(offsetFromCenter) / (offsetRadius + 1));
        let translateX = -50;

        if (offsetRadius !== 0) {
            if (index === 0) {
                translateX = 0;
            } else if (index === totalPresentables - 1) {
                translateX = -100;
            }
        }

        if (offsetFromCenter > 0) {
            translateX += translateXoffset;
        } else if (offsetFromCenter < 0) {
            translateX -= translateXoffset;
        }
        return translateX;
    };

    return (
        <div
            className={clsx(
                "absolute top-0 right-0 bottom-0 left-0 transition-[opacity,z-index] duration-300",
                showNotesOverlay ? "opacity-100 z-[100]" : "opacity-0 z-[-1]"
            )}
        >
            <div className="absolute w-full h-full bg-black bg-opacity-50" />
            <div
                ref={sliderRef}
                id="slider"
                className={clsx(
                    "absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-[101] max-w-[1200px] w-[calc(100%-24px)] h-[50%] lg:h-full lg:w-[80%] px-4"
                )}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
            >
                <Carousel
                    goToSlideDelay={0}
                    slides={slides}
                    goToSlide={state.goToSlide}
                    offsetRadius={state.offsetRadius}
                    showNavigation={state.showNavigation}
                    animationConfig={state.config}
                    offsetFn={(offsetFromCenter: number, index: number) => {
                        const translateX = getDefaultTranslateX(offsetFromCenter, 1, index);
                        return {
                            transform: `translateY(-50%) translateX(${translateX}%) scale(${
                                1 - Math.abs(offsetFromCenter / 5)
                            })`,
                            opacity: 1 - Math.abs(offsetFromCenter / 3),
                        };
                    }}
                />
            </div>

            <div
                className={clsx(
                    "z-[102] absolute left-1/2 bottom-5 -translate-x-1/2 transition-[opacity,background]",
                    showNotesOverlay ? "opacity-1 duration-[500ms] delay-500" : "opacity-0 duration-[0ms]"
                )}
            >
                <CloseButton onClick={() => updateShowNotesOverlay(false)} />
            </div>
        </div>
    );
};

export default inject("store")(observer(NotesOverlay));
