import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { fetchFromStrapi } from "utils/graphqlClient";
import { getDeviceOs } from "utils/deviceHelper";
import {
    allI18nsQuery,
    metaQuery,
    basicUiQuery,
    toastQuery,
    videoQuery,
    endScreenOverlayQuery,
    notesOverlayQuery,
} from "utils/graphql/queries";
import { CommonPageDataProps } from "types/types";
import {
    I18NEntity,
    MetaEntity,
    BasicUiEntity,
    ToastEntity,
    VideoEntity,
    EndScreenOverlayEntity,
    NotesOverlayEntity,
} from "types/strapi";
import { VideoEnums } from "enums/Video";

import { inject, observer } from "mobx-react";
import { Store } from "stores/store";

import axios from "axios";

import MetaData from "components/MetaData";
import Background from "components/Background";
import Video from "components/Video";
import Toasts from "components/Toasts";
import NotesOverlay from "components/NotesOverlay";
import SequenceOverlay from "components/SequenceOverlay";
import EndScreenOverlay from "components/EndScreenOverlay";

interface IndexProps extends CommonPageDataProps {
    i18nData: I18NEntity[];
    store?: Store;
    locales: string[];
    localeNames: string[];
    metaData: MetaEntity;
    basicUiData: BasicUiEntity;
    toastData: ToastEntity;
    videoData: VideoEntity;
    endScreenOverlayData: EndScreenOverlayEntity;
    notesOverlayData: NotesOverlayEntity;
}

const Index: React.FunctionComponent<IndexProps> = ({
    store,
    locales,
    localeNames,
    metaData,
    basicUiData,
    toastData,
    videoData,
    endScreenOverlayData,
    notesOverlayData,
}) => {
    if (!store) {
        throw Error("Store was not setup correctly");
    }
    const router = useRouter();
    const { referrer } = router.query;

    const {
        locale,
        currentVideo,
        endScreenOverlayDataLocalized,
        isConnected,
        updateLocale,
        updateLocales,
        updateLocaleNames,
        updateShowNotesOverlay,
        updateBasicUiDataLocalized,
        updateToastDataLocalized,
        updateVideoDataLocalized,
        updateEndScreenOverlayDataLocalized,
        updateNotesOverlayDataLocalized,
    } = store;

    useEffect(() => {
        if (notesOverlayData.attributes && notesOverlayData.attributes.locale === locale) {
            updateNotesOverlayDataLocalized(notesOverlayData.attributes);
        } else {
            if (notesOverlayData.attributes?.localizations) {
                for (const notesOverlayAlternativeLocale of notesOverlayData.attributes.localizations.data) {
                    if (notesOverlayAlternativeLocale.attributes?.locale) {
                        if (notesOverlayAlternativeLocale.attributes.locale === locale) {
                            updateNotesOverlayDataLocalized(notesOverlayAlternativeLocale.attributes);
                            break;
                        }
                    }
                }
            }
        }
    }, [locale]);

    useEffect(() => {
        if (basicUiData.attributes && basicUiData.attributes.locale === locale) {
            updateBasicUiDataLocalized(basicUiData.attributes);
        } else {
            if (basicUiData.attributes?.localizations) {
                for (const basicUiAlternativeLocale of basicUiData.attributes.localizations.data) {
                    if (basicUiAlternativeLocale.attributes?.locale) {
                        if (basicUiAlternativeLocale.attributes.locale === locale) {
                            updateBasicUiDataLocalized(basicUiAlternativeLocale.attributes);
                            break;
                        }
                    }
                }
            }
        }
    }, [locale]);

    useEffect(() => {
        if (basicUiData.attributes && basicUiData.attributes.locale === locale) {
            updateBasicUiDataLocalized(basicUiData.attributes);
        } else {
            if (basicUiData.attributes?.localizations) {
                for (const basicUiAlternativeLocale of basicUiData.attributes.localizations.data) {
                    if (basicUiAlternativeLocale.attributes?.locale) {
                        if (basicUiAlternativeLocale.attributes.locale === locale) {
                            updateBasicUiDataLocalized(basicUiAlternativeLocale.attributes);
                            break;
                        }
                    }
                }
            }
        }
    }, [locale]);

    useEffect(() => {
        if (toastData.attributes && toastData.attributes.locale === locale) {
            updateToastDataLocalized(toastData.attributes);
        } else {
            if (toastData.attributes?.localizations) {
                for (const toastAlternativeLocale of toastData.attributes.localizations.data) {
                    if (toastAlternativeLocale.attributes?.locale) {
                        if (toastAlternativeLocale.attributes.locale === locale) {
                            updateToastDataLocalized(toastAlternativeLocale.attributes);
                            break;
                        }
                    }
                }
            }
        }
    }, [locale]);

    useEffect(() => {
        if (videoData.attributes && videoData.attributes.locale === locale) {
            updateVideoDataLocalized(videoData.attributes);
        } else {
            if (videoData.attributes?.localizations) {
                for (const videoAlternativeLocale of videoData.attributes.localizations.data) {
                    if (videoAlternativeLocale.attributes?.locale) {
                        if (videoAlternativeLocale.attributes.locale === locale) {
                            updateVideoDataLocalized(videoAlternativeLocale.attributes);
                            break;
                        }
                    }
                }
            }
        }
    }, [locale]);

    useEffect(() => {
        if (endScreenOverlayData.attributes && endScreenOverlayData.attributes.locale === locale) {
            updateEndScreenOverlayDataLocalized(endScreenOverlayData.attributes);
        } else {
            if (endScreenOverlayData.attributes?.localizations) {
                for (const endScreenOverlayAlternativeLocale of endScreenOverlayData.attributes.localizations.data) {
                    if (endScreenOverlayAlternativeLocale.attributes?.locale) {
                        if (endScreenOverlayAlternativeLocale.attributes.locale === locale) {
                            updateEndScreenOverlayDataLocalized(endScreenOverlayAlternativeLocale.attributes);
                            break;
                        }
                    }
                }
            }
        }
    }, [locale]);

    useEffect(() => {
        updateLocales(locales);
        updateLocaleNames(localeNames);
    }, []);

    useEffect(() => {
        const browserLanguage = navigator.language;
        const { locale: parameterLocale } = router.query

        console.log(router.query)

        console.log(parameterLocale)

        let languageInitialized = false;

        if (typeof parameterLocale === "string") {
            for (let localeIndex = 0; localeIndex < locales.length && !languageInitialized; localeIndex++) {
                const locale = locales[localeIndex];

                if (parameterLocale === locale) {
                    languageInitialized = true;
                    updateLocale(locale, true);
                }
            }
        }

        for (let localeIndex = 0; localeIndex < locales.length && !languageInitialized; localeIndex++) {
            const locale = locales[localeIndex];

            if (browserLanguage.includes(locale)) {
                languageInitialized = true;
                updateLocale(locale, true);
            }
        }

        if (!languageInitialized) {
            updateLocale(locales[0], true);
        }
    }, [locales, router.query]);

    useEffect(() => {
        if (referrer === "qr") {
            if (getDeviceOs() === "ios" && endScreenOverlayDataLocalized?.appStoreUrl) {
                window.location.href = endScreenOverlayDataLocalized?.appStoreUrl;
            }

            if (getDeviceOs() === "android" && endScreenOverlayDataLocalized?.playStoreUrl) {
                window.location.href = endScreenOverlayDataLocalized?.playStoreUrl;
            }
        }
    }, [referrer]);

    function openNote() {
        updateShowNotesOverlay(true);
    }

    return (
        <>
            <MetaData metaData={metaData} />

            <div className="relative h-[520px] xs:h-[640px] overflow-hidden">
                <Background>
                    <Video />
                </Background>

                {currentVideo !== VideoEnums.BEGINNING && <Toasts />}

                {!isConnected && (
                    <div
                        className="absolute right-1/2 top-0 --translate-y-[100%] -translate-x-[30%] w-[250px] h-[120px] z-40 cursor-pointer"
                        onClick={openNote}
                    ></div>
                )}

                <NotesOverlay />

                <SequenceOverlay />

                <EndScreenOverlay />
            </div>
        </>
    );
};

export async function getStaticProps() {
    const localesData = await axios.get<
        {
            id: number;
            name: string;
            code: string;
            createdAt: string;
            updatedAt: string;
            isDefault: boolean;
        }[]
    >(process.env.NEXT_PUBLIC_STRAPI_URL + "/api/i18n/locales");

    const locales = [];
    const localeNames = [];

    for (let localeDataIndex = 0; localeDataIndex < localesData.data.length; localeDataIndex++) {
        const localeData = localesData.data[localeDataIndex];

        locales.push(localeData.code);
        localeNames.push(localeData.name.split(" ")[0]);
    }

    const i18nData = await fetchFromStrapi(allI18nsQuery);
    const metaData = await fetchFromStrapi(metaQuery);
    const basicUiData = await fetchFromStrapi(basicUiQuery);
    const toastData = await fetchFromStrapi(toastQuery);
    const videoData = await fetchFromStrapi(videoQuery);
    const endScreenOverlayData = await fetchFromStrapi(endScreenOverlayQuery);
    const notesOverlayData = await fetchFromStrapi(notesOverlayQuery);

    return {
        props: {
            i18nData: i18nData.i18Ns.data,
            locales,
            localeNames,
            metaData: metaData.meta.data,
            basicUiData: basicUiData.basicUi.data,
            toastData: toastData.toast.data,
            videoData: videoData.video.data,
            endScreenOverlayData: endScreenOverlayData.endScreenOverlay.data,
            notesOverlayData: notesOverlayData.notesOverlay.data,
        },
    };
}

export default inject("store")(observer(Index));
